<template>
  <span v-if="$user.role === 'ROLE_CZN_TERRITORIAL'">{{ $user.department.name }}</span>
  <span v-else-if="$user.role === 'ROLE_CZN_MANAGER' || $user.role === 'ROLE_REGIONAL_AGENCY'|| $user.role === 'ROLE_REGIONAL_ADMINISTRATOR'" class="two-columns">
    <span class="left-element" style="padding-top: 18px">{{ $user.region.name }}</span>
    <v-autocomplete label="ЦЗН"
                    v-model="department"
                    :items="region.departments"
                    item-text="name"
                    return-object
                    class="right-element"
                    @input="departmentChange()"
    ></v-autocomplete>
    <p style="clear: both"></p>
  </span>
  <span v-else class="two-columns">
    <v-autocomplete label="Регион"
                    v-model="region"
                    :items="$cznDepartmentsByRegionList"
                    item-text="name"
                    return-object
                    class="left-element"
                    @change="setDefaultDepartment()"
    ></v-autocomplete>
    <v-autocomplete label="ЦЗН"
                    v-model="department"
                    :items="region.departments"
                    item-text="name"
                    return-object
                    class="right-element"
                    @input="departmentChange()"
    ></v-autocomplete>
    <p style="clear: both"></p>
  </span>
</template>

<script>
export default {
  name: 'DepartmentInfo',
  props: [],

  data: () => ({
    region: {},
    department: {},
  }),

  methods:{
    findRegion() {
      for (let reg of this.$cznDepartmentsByRegionList) {
        if (reg.code === this.$user.region.code) {
          this.region = reg
        }
      }
    },

    departmentChange() {
      this.$emit('minput', this.department.name);
      this.$emit('depObj', this.department);
      this.$forceUpdate();
    },

    setDefaultDepartment() {
      if (this.$user.role === 'ROLE_CZN_MANAGER') {
        this.department = this.$user.department
      } else {
        this.department = this.region.departments[0];
      }
      this.$emit('reginput', this.region);
      this.$emit('minput', this.department.name);
      this.$emit('depObj', this.department);
      this.$forceUpdate();
    }
  },

  beforeMount() {
    if (this.$user.role === 'ROLE_CZN_MANAGER' || this.$user.role === 'ROLE_REGIONAL_AGENCY'  || this.$user.role === 'ROLE_REGIONAL_ADMINISTRATOR' ) {
      this.findRegion();
      this.setDefaultDepartment();
    }
    if (this.$user.role === 'ROLE_ADMIN' || this.$user.role === 'ROLE_VNII' || this.$user.role === 'ROLE_MINTRUD') {
      this.region = this.$cznDepartmentsByRegionList[0];
      this.setDefaultDepartment();
    }
  }
}
</script>